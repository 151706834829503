<template>
  <div>
    <main style="width: 100%">
      <!-- 头部横幅  v-lazy:background-image="require('./images/okok.png')" -->
      <div class="banner"
      
      >
      <img  v-lazy="require('./images/T3kDsib3.webp')">
        <!-- 横幅内容区域 -->
      <div>
          <h2 class="title">旅游产品分销平台</h2>
        <p class="introduce">
          专业的互联网技术团队打造安全、稳定、高效的旅游系统，随时提供技术支持与服务
        </p>
      </div>
      </div>
      <!-- 核心产品与服务 -->
      <div class="center_first">
        <h2>核心产品与服务</h2>
        <p>为旅游人实现全面信息化，从交易、管理、营销全方位赋能</p>
        <div class="img_first">
          <div class="sons">
            <img src="./images/yrUS8ajC.webp" />
            <p>为文旅企业提供免费办公软件支持</p>
          </div>
          <div class="noyou"></div>
          <div class="sons">
            <img src="./images/lnYeGteH.webp" />
            <p>提供分销与供应支持</p>
          </div>
        </div>
      </div>
      <!-- 我们的使命 -->
      <div class="colorbg">
        <div class="centertext">我们的使命</div>
        <div class="imgbox">
          <div class="onebig">
            <div class="imgsomll"></div>
            <span>连接旅游全产业链上下游资源</span>
          </div>
           <div class="onebig">
            <div class="imgsoml2"></div>
            <span>做旅游行业最好用的应用工具</span>
          </div>
           <div class="onebig">
            <div class="imgsomll3"></div>
            <span>构建旅游生态圈</span>
          </div>
        </div>
      </div>
      <!-- 解决方案 -->
      <div class="fourbgone">
        <h2 class="textline">解决方案</h2>
        <div class="fourboxsone">
          <img src="./images/lTUEEasZ.webp" >
        </div>
      </div>
      
      <!-- 连接-工具-生态 -->
    <div>
 <div class="colorbg1">
        <div class="centertext">链接-工具-生态</div>
        <div class="imgbox">
          <div class="onebig1">
            <div class="iconone"></div>
            <div  class="bigtext">链接</div>
            <div class="smalltext">以供应链为核心,将旅游行业全链条中的各个重要角色通过系统连接起来,打通各个环节。</div>
          </div>
           <div class="onebig1">
            <div class="icontwo"></div>
            <div class="bigtext">工具</div>
            <div class="smalltext">专注旅游行业,拥有最专业的开发团队,最好用的应用工具,帮助每个企业拥有自己的平台。</div>
          </div>
           <div class="onebig1">
            <div class="iconthree"></div>
            <div  class="bigtext">生态</div>
            <div class="smalltext">以区域为核心,通过帮助企业信息化改造,为您构建旅游生态圈。</div>
          </div>
        </div>
      </div>
    </div>
      <!-- 页尾、、、、、、、、、、、、、 -->
      
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "traveldistribution",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
* {
    
     box-sizing: border-box;
}
.banner {
  height: 600px;
  width: 100%;
  color: #fff;
  
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-position: top 0;
  position: relative;
  img[lazy="loading"] {
    margin-top: 150px;
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
	height: 600px;
  width: 100%;
	}
  div{
    .title {
    width: 412px;
    height: 67px;

    font-weight: 500;
    line-height: 67px;
    letter-spacing: 3px;
    position: absolute;
    left: 360px;
    top: 230px;
    font-size: 48px;
    
    font-weight: 500;
    color: #fff;
  }
  .introduce {
    width: 697px;
    height: 66px;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 360px;
    top: 317px;
  }
  }
}
.introduce,
.title {
  animation: sport 0.5s;
  transform: translateY(0px);
  width: 300px;
  height: 80px;
}
//设置头部文字动画效果
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.center_first:after {
  content: "";
  display: block;
  clear: both;
}
.center_first {
  
  h2 {
    margin-top: 80px;
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
  }
  p {
    margin-top: 24px;
    font-size: 20px;
    font-weight: 400;
    color: #565656;
    line-height: 40px;
    letter-spacing: 1px;
    font-family: PingFangSC-Regular, PingFang SC;
  }

  .btn {
    width: 156px;
    height: 48px;
    margin-top: 40px;
    border: 3px solid #0079ff;
    border-radius: 24px;
    line-height: 48px;
    margin: 0 auto;
  }
}
.img_first {
  display: flex;
  margin-left: 360px;
  margin-bottom: 100px;
  .sons {
    margin-top: 84px;
    img {
      width: 585px;
      height: 520px;
      vertical-align: bottom;
    }
    p {
      margin-top: 0 !important;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 0px 0px 6px 6px;
      width: 585px;
      height: 98px;
      font-size: 18px;
      
      font-weight: 500;
      color: #333333;
      line-height: 98px;
    }
  }
  .noyou {
    width: 30px;
  }
}
.colorbg1 {
  height: 696px;
  background: #f6f8fb;
  padding-top: 80px;
  .centertext {
    font-size: 40px;
    
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
  }
  .imgbox {
    margin-top: 60px;
    width: 1200px;
    height: 320px;
    margin-left: 360px;
    display: flex;
    justify-content: space-between;
    .onebig1{
         padding-top: 80px;
      width: 380px;
      height: 400px;
      background: #ffffff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
        .iconone {
        width: 92px;
        height: 92px;
        margin: 0 auto;
        background-size: 100% 100%;
        margin-bottom: 16px;
        background-position: center;
         background-image: url("./images/iconone.png");
        div{
font-size: 20px;

font-weight: 500;
color: #333333;
line-height: 28px;
        }
      }
     .icontwo{
         width: 92px;
        height: 92px;
        margin: 0 auto;
        background-size: 100% 100%;
        margin-bottom: 16px;
        background-image: url("./images/icontwo.png");
        background-position: center;
     }
     .iconthree{
         width: 92px;
        height: 92px;
        margin: 0 auto;
        background-size: 100% 100%;
        margin-bottom: 16px;
        background-image: url("./images/iconthree.png");
        background-position: center;
     }
     .bigtext{
         margin-bottom: 16px;
          font-size: 28px;

font-weight: 500;
color: #333333;
line-height: 28px;
     }
     .smalltext {
         margin: 0 auto;
         width: 268px;

font-size: 20px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
line-height: 30px;
text-align: left;
     }
    }
  }
}
.colorbg {
  height: 616px;
  background: #f6f8fb;
  padding-top: 80px;
  .centertext {
    font-size: 40px;
    
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
  }
  .imgbox {
    margin-top: 60px;
    width: 1200px;
    height: 320px;
    margin-left: 360px;
    display: flex;
    justify-content: space-between;
    .onebig {
      padding-top: 80px;
      width: 380px;
      height: 320px;
      background: #ffffff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      .imgsomll {
          
        width: 92px;
        height: 92px;
        margin: 0 auto;
       background-size: 100% 100%;
        margin-bottom: 16px;
        background-position: center;
         background-image: url("./images/icon1.png");
      }
     .imgsoml2{
         width: 92px;
        height: 92px;
        margin: 0 auto;
        background-size: 100% 100%;
        margin-bottom: 16px;
        background-image: url("./images/icon2.png");
        background-position: center;
     }
     .imgsomll3{
         width: 92px;
        height: 92px;
        margin: 0 auto;
        background-size: 100% 100%;
        margin-bottom: 16px;
        background-image: url("./images/icon3.png");
        background-position: center;
     }
      span {
        font-size: 20px;
        
        font-weight: 500;
        color: #333333;
        line-height: 28px;
      }
    }
  
  }
}
.fourbgone{
    .textline{
        font-size: 40px;
        
        font-weight: 500;
        color: #333333;
        margin: 80px 0 60px;
    }
    .fourboxsone {
    width: 1200px;
    height: 340px;
    margin: 0 auto;
    margin-bottom: 100px;
   img{
     width: 100%;
     height: 100%;
   }
}

}
 


</style>